<template>
  <div class="scroll-smooth">
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <button
      type="button"
      @click="toTop"
      class="fixed z-90 bottom-8 right-8 border-0 w-16 h-16 rounded-full drop-shadow-md bg-primavera-500 text-white text-3xl font-bold"
      :class="{ block: windowScroll, hidden: !windowScroll }"
    >
      <i class="fa fa-arrow-up" style="font-size: 22px"></i>
    </button>
  </div>
</template>

<script setup>
const { y } = useWindowScroll({ behavior: "smooth" });

const windowScroll = computed(() => {
  if (y.value > 100) {
    return true;
  } else {
    return false;
  }
});

function toTop() {
  y.value = 0;
}
</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}

html {
  scroll-behavior: smooth !important;
}
</style>
