import { default as acerca_45dew8sSJkoEqBMeta } from "C:/wamp64/www/dev/denet_parallel/pagina_WEB_Primavera/nuxt/pages/acerca-de.vue?macro=true";
import { default as consulta_45facturaUeZxcpxCoJMeta } from "C:/wamp64/www/dev/denet_parallel/pagina_WEB_Primavera/nuxt/pages/consulta-factura.vue?macro=true";
import { default as contactodlVP5QZhpYMeta } from "C:/wamp64/www/dev/denet_parallel/pagina_WEB_Primavera/nuxt/pages/contacto.vue?macro=true";
import { default as indexSqmFdAZfaQMeta } from "C:/wamp64/www/dev/denet_parallel/pagina_WEB_Primavera/nuxt/pages/index.vue?macro=true";
export default [
  {
    name: acerca_45dew8sSJkoEqBMeta?.name ?? "acerca-de",
    path: acerca_45dew8sSJkoEqBMeta?.path ?? "/acerca-de",
    meta: acerca_45dew8sSJkoEqBMeta || {},
    alias: acerca_45dew8sSJkoEqBMeta?.alias || [],
    redirect: acerca_45dew8sSJkoEqBMeta?.redirect,
    component: () => import("C:/wamp64/www/dev/denet_parallel/pagina_WEB_Primavera/nuxt/pages/acerca-de.vue").then(m => m.default || m)
  },
  {
    name: consulta_45facturaUeZxcpxCoJMeta?.name ?? "consulta-factura",
    path: consulta_45facturaUeZxcpxCoJMeta?.path ?? "/consulta-factura",
    meta: consulta_45facturaUeZxcpxCoJMeta || {},
    alias: consulta_45facturaUeZxcpxCoJMeta?.alias || [],
    redirect: consulta_45facturaUeZxcpxCoJMeta?.redirect,
    component: () => import("C:/wamp64/www/dev/denet_parallel/pagina_WEB_Primavera/nuxt/pages/consulta-factura.vue").then(m => m.default || m)
  },
  {
    name: contactodlVP5QZhpYMeta?.name ?? "contacto",
    path: contactodlVP5QZhpYMeta?.path ?? "/contacto",
    meta: contactodlVP5QZhpYMeta || {},
    alias: contactodlVP5QZhpYMeta?.alias || [],
    redirect: contactodlVP5QZhpYMeta?.redirect,
    component: () => import("C:/wamp64/www/dev/denet_parallel/pagina_WEB_Primavera/nuxt/pages/contacto.vue").then(m => m.default || m)
  },
  {
    name: indexSqmFdAZfaQMeta?.name ?? "index",
    path: indexSqmFdAZfaQMeta?.path ?? "/",
    meta: indexSqmFdAZfaQMeta || {},
    alias: indexSqmFdAZfaQMeta?.alias || [],
    redirect: indexSqmFdAZfaQMeta?.redirect,
    component: () => import("C:/wamp64/www/dev/denet_parallel/pagina_WEB_Primavera/nuxt/pages/index.vue").then(m => m.default || m)
  }
]