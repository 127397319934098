
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "c689ee9c-cb95-4ae4-a8eb-f6ca8cdd8bb0"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "C:/wamp64/www/dev/denet_parallel/pagina_WEB_Primavera/nuxt/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
